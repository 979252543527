import {useTranslation} from "react-i18next";
import {Marker} from "../interfaces/MarkersResult";
import {isMobile} from "../services/Utilities";
import {useEffect, useRef, useState} from "react";
import {StorePin} from "./StorePin";

interface ClosestPointModalProps {
  point: Marker | undefined;
  distance?: number;
  map: google.maps.Map | undefined;
  closeTab: () => void;
}

export const ClosestPointModal = ({
                                    point,
                                    distance,
                                    map,
                                    closeTab,
                                  }: ClosestPointModalProps) => {
  const {t} = useTranslation();
  const _isMobile = isMobile();
  const animateRef = useRef<HTMLDivElement>(null);
  const injectStyle = (style: string) => {
    const styleElement = document.createElement("style");
    let styleSheet = null;
    document.head.appendChild(styleElement);
    styleSheet = styleElement.sheet;
    styleSheet?.insertRule(style, styleSheet.cssRules.length);
  };

  const marqueeWidth = document.getElementById("marquee")?.clientWidth ?? 0;
  const [translatedPx, setTranslatedPx] = useState(0);
  useEffect(() => {
    if (animateRef.current)
      setTranslatedPx(animateRef.current.scrollWidth - marqueeWidth);
  }, [marqueeWidth]);
  const marqueeStyle = `
      @keyframes marquee {
        0%   { transform: translate(0%) }
        50% { transform: translate(${-translatedPx}px) }
        100%{ transform: translate(0%) }
      }
    `;
  injectStyle(marqueeStyle);
  const clickHandler = () => {
    map?.setCenter({
      lat: Number(point?.lat),
      lng: Number(point?.lng),
    });
    closeTab();
  };
  if (!point) return null;
  return (
    <div
      style={{
        borderRadius: "22.054px",
        border: "2px solid #BAE0D5",
        background: "#FFF",
        boxShadow:
          "0px 340px 95.568px 0px rgba(14, 65, 51, 0.00), 0px 216.865px 86.378px 0px rgba(14, 65, 51, 0.01), 0px 123.135px 73.514px 0px rgba(14, 65, 51, 0.05), 0px 55.135px 55.135px 0px rgba(14, 65, 51, 0.09), 0px 12.865px 29.405px 0px rgba(14, 65, 51, 0.10)",
        position: "fixed",
        height: _isMobile ? "80px" : "140px",
        width: _isMobile ? "70vw" : "40vw",
        left: "50%",
        bottom: "30px",
        transform: "translate(-50%)",
        display: "flex",
        alignItems: "center",
        justifyContent: _isMobile ? "flex-start" : "space-evenly",
        gap: _isMobile ? "10px" : "",
        paddingLeft: _isMobile ? "10px" : "",
      }}
      onClick={clickHandler}
    >
      <StorePin
        style={{
          height: _isMobile ? "32px" : "48px",
        }}
      />
      <div
        style={{
          textAlign: "left",
          maxWidth: _isMobile ? "50vw" : "30vw",
        }}
      >
        <div
          style={{
            fontSize: _isMobile ? "12px" : "20px",
            fontWeight: 400,
            lineHeight: _isMobile ? "16px" : "24px",
          }}
        >
          {`${t("closestModalTitle")}:`}
        </div>
        <div
          className={"marquee"}
          id={"marquee"}
          style={{
            fontSize: _isMobile ? "16px" : "28px",
            fontWeight: _isMobile ? 500 : 700,
            lineHeight: _isMobile ? "24px" : "33.6px",
            whiteSpace: "nowrap",
            overflowX: "scroll",
            display: "flex",
            scrollbarWidth: "none",
          }}
        >
          <span
            ref={animateRef}
            onMouseEnter={() => animateRef.current?.getAnimations()[0]?.pause()}
            onMouseLeave={() => animateRef.current?.getAnimations()[0]?.play()}
            style={{
              animation:
                animateRef.current !== null &&
                animateRef.current.clientWidth > marqueeWidth
                  ? "marquee 10s linear infinite"
                  : "none",
              display: "inline-block",
              whiteSpace: "nowrap",
            }}
          >
            {point.name}
          </span>
        </div>
        {distance ? (
          <div
            style={{
              fontSize: _isMobile ? "14px" : "28px",
              fontWeight: 400,
              lineHeight: _isMobile ? "20px" : "33.6px",
            }}
          >
            {distance >= 1000
              ? Math.round(distance / 100) / 10
              : Math.round(distance)}
            {distance >= 1000 ? "km" : "m"}
          </div>
        ) : null}
      </div>
    </div>
  );
};
