import { useEffect, useRef, MouseEventHandler } from "react";
import TargetIcon from "../icons/Target.svg";
import { isMobile } from "../services/Utilities";
interface CenterMapButtonProps {
  map: google.maps.Map | undefined;
  centerLocation?: google.maps.LatLngLiteral;
  locationPermission: "ACCEPTED" | "REJECTED" | undefined;
  setShowPermissionText: (arg0: boolean) => void;
}

export const CenterMapButton = ({
  map,
  centerLocation,
  locationPermission,
  setShowPermissionText,
}: CenterMapButtonProps) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const _isMobile = isMobile();
  useEffect(() => {
    if (map && buttonRef.current)
      map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(
        buttonRef.current,
      );
  }, [map]);

  const onClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (map && centerLocation && locationPermission === "ACCEPTED") {
      map.setCenter(centerLocation);
    } else {
      e.stopPropagation();
      setShowPermissionText(true);
    }
  };

  return (
    <button
      ref={buttonRef}
      onClick={onClick}
      style={{
        width: "48px",
        height: "48px",
        background:
          locationPermission === "ACCEPTED"
            ? "linear-gradient(269deg, #0087E5 0%, #0069B3 100%)"
            : "#c3bcbc",
        boxShadow: "0px 2px 3px rgba(0, 58, 98, 0.15)",
        borderRadius: 60,
        border:
          locationPermission === "ACCEPTED" ? "1px #3387C2 solid" : "#c3bcbc",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        display: "inline-flex",
        marginRight: _isMobile ? "30px" : "6px",
        marginBottom: _isMobile ? "100px" : "",
      }}
    >
      <img src={TargetIcon} alt="Target Icon" />
    </button>
  );
};
