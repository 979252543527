import {
  MarkerClusterer,
  SuperClusterAlgorithm,
} from "@googlemaps/markerclusterer";
import { useCallback, useEffect, useRef, useState } from "react";
import { DEFAULT_ZOOM, PRODUCT_DETAILS } from "../Constants";
import CurrentLocationPin from "../icons/CurrentLocationPin.svg";
import { LocationService } from "../services/LocationService";
import { isMobile } from "../services/Utilities";
import { CenterMapButton } from "./CenterMapButton";
import _ from "lodash";

interface MapProps {
  locations: ReadonlyArray<google.maps.LatLngLiteral>;
  onMarkerClick: (data: google.maps.LatLngLiteral) => void;
  center?: google.maps.LatLngLiteral;
  userLocation?: google.maps.LatLngLiteral;
  onCenterChange?: (newCenter: google.maps.LatLngLiteral) => void;
  onMapLoaded?: (map: google.maps.Map) => void;
  map: google.maps.Map | undefined;
  mapId: string | undefined;
  locationPermission: "ACCEPTED" | "REJECTED" | undefined;
  setShowPermissionText: (arg0: boolean) => void;
}

const adjustMapToLocations = ({
  locations,
  map,
  center,
}: {
  locations: ReadonlyArray<google.maps.LatLngLiteral>;
  map: google.maps.Map | null | undefined;
  center: google.maps.LatLngLiteral;
}) => {
  if (!map) return;
  if (locations.length === 0) return;
  // map.setZoom(20);

  const closest = LocationService.GetClosestPoint(center, locations);

  const bounds = new google.maps.LatLngBounds();
  bounds.extend(center);
  bounds.extend(closest);
  map.fitBounds(bounds);
  // let bounds = map.getBounds();

  // while (!bounds?.contains(closest)) {
  //   map.setZoom((map.getZoom() ?? 20) - 1);
  //   bounds = map.getBounds();
  // }
};

export const GoogleMaps = ({
  locations,
  center,
  userLocation,
  onCenterChange,
  onMarkerClick,
  onMapLoaded,
  map,
  mapId,
                             locationPermission,
                             setShowPermissionText,
}: MapProps) => {
  const [zoom, setZoom] = useState(DEFAULT_ZOOM);
  const [firstLoad, setFirstLoad] = useState(true);
  const ref = useRef<HTMLDivElement | null>(null);
  // const map = useRef<google.maps.Map>();
  const markers = useRef<MarkerClusterer>();
  const _isMobile = isMobile();
  let timeout = useRef<NodeJS.Timeout>();
  const currentMarkers = useRef<google.maps.LatLngLiteral[]>([]);

  const addSingleMarkers = useCallback(
    ({
      locations,
      map,
    }: {
      locations: ReadonlyArray<google.maps.LatLngLiteral>;
      map: google.maps.Map | null | undefined;
    }) => {
      const markers = locations.map((position) => {
        const newMarker = new google.maps.Marker({
          position,
          map,
          icon: PRODUCT_DETAILS.pinSVG,
        });
        newMarker.addListener("click", () => onMarkerClick(position));
        return newMarker;
      });

      return markers;
    },
    [onMarkerClick],
  );

  const addClusterMarkers = useCallback(
    ({
      locations,
      map,
      cluster,
    }: {
      locations: ReadonlyArray<google.maps.LatLngLiteral>;
      map: google.maps.Map | null | undefined;
      cluster?: MarkerClusterer;
    }) => {
      if (!cluster || !map) return;

      const mapBounds = map.getBounds();

      const filteredLocations = locations.filter((item) => {
        return mapBounds?.contains(item);
      });

      if (_.isEqual(currentMarkers.current, filteredLocations)) return;
      cluster.clearMarkers();
      const markers = addSingleMarkers({ locations: filteredLocations, map });
      cluster.addMarkers(markers);
      currentMarkers.current = filteredLocations;
    },
    [addSingleMarkers],
  );

  useEffect(() => {
    if (!ref.current || map) return;

    const _map = new window.google.maps.Map(ref.current, {
      center: center,
      zoom: zoom,
      disableDefaultUI: true,
      zoomControl: !_isMobile,
      clickableIcons: false,
      mapId,
    });

    _map.addListener("idle", () => {
      if (!_map) return;

      const currentZoom = _map.getZoom();
      const newCenter = _map.getCenter();
      if (currentZoom !== zoom) setZoom(_map.getZoom() ?? DEFAULT_ZOOM);

      if (!onCenterChange || !newCenter) return;

      timeout.current && clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        onCenterChange({
          lat: newCenter.lat(),
          lng: newCenter.lng(),
        });
      }, 500);
    });

    onMapLoaded && onMapLoaded(_map);

    markers.current = new MarkerClusterer({
      markers: [],
      map: _map,
      algorithm: new SuperClusterAlgorithm({
        radius: 175, // cluster size
      }),
    });
  }, [
    _isMobile,
    center,
    locations,
    map,
    mapId,
    onCenterChange,
    onMapLoaded,
    zoom,
  ]);

  useEffect(() => {
    if (!userLocation || !map) return;

    new google.maps.Marker({
      position: userLocation,
      map,
      icon: CurrentLocationPin,
    });

    map.setCenter(userLocation);
  }, [map, userLocation]);

  useEffect(() => {
    if (!map) return;

    if (firstLoad && center && locations.length > 0) {
      adjustMapToLocations({ locations, map, center });
      setFirstLoad(false);
    }

    addClusterMarkers({
      cluster: markers.current,
      locations,
      map,
    });
  }, [
    center,
    userLocation,
    firstLoad,
    locations,
    markers,
    map,
    addClusterMarkers,
  ]);
  const permissionsModalHandler = () => {
    setShowPermissionText(false);
  };
  return (
    <div
      style={{ width: "100%", height: "100%", position: "absolute" }}
      ref={ref}
      onClick={permissionsModalHandler}
    >
      <CenterMapButton
        map={map}
        centerLocation={userLocation}
        locationPermission={locationPermission}
        setShowPermissionText={setShowPermissionText}
      />
    </div>
  );
};
