import {FC} from "react";
import CookieConsent from "react-cookie-consent";
import {isMobile} from "../services/Utilities";
import {useTranslation} from "react-i18next";
import "./LocationDetails.css";
import {COOKIES_CONSENT_COOKIE} from "../Constants";

interface CookieConsentProps {
  roundedTopCorners?: boolean;
  buttonOriantation?: "vertical" | "horizontal";
  roundedButtons?: boolean;
  displayTitle?: boolean
}

const _CookieConsent: FC<CookieConsentProps> = () => {
  const _isMobile = isMobile();
  const {t} = useTranslation();

  const MainButtonStyle: React.CSSProperties = {
    // width: _isMobile ? "100px" : "160px",
    height: _isMobile ? "55px" : "48px",
    padding: "0px 53px 0px 53px",
    borderRadius: "12px",
    fontFamily: "Cera Pro Regular",
    fontSize: "16px",
    fontWeight: 700,
    letterSpacing: "0em",
    margin: _isMobile ? "8px" : "15px",
  };
  return (
    <CookieConsent
      enableDeclineButton
      buttonText={t("accept")}
      declineButtonText={t("decline")}
      cookieName={COOKIES_CONSENT_COOKIE}
      contentStyle={{
        margin: _isMobile ? "10px 8px 10px 8px" : "15px",
        textAlign: "left",
      }}
      buttonStyle={{
        ...MainButtonStyle,
        color: "#F0F8FF",
        background: "#0079CB",
        border: "2px solid #3387C2",
      }}
      declineButtonStyle={{
        ...MainButtonStyle,
        color: "#0079CB",
        background: "#FFFFFF",
        border: "2px solid #7BCCFE",
      }}
      style={{
        background: "#FFFFFF",
        color: "#6D6D6D",
        fontFamily: "Cera Pro Regular",
        fontSize: "16px",
        fontWeight: _isMobile ? 400 : 500,
        lineHeight: "24px",
        letterSpacing: "0em",
        textAlign: "left",
        verticalAlign: "center",
        alignItems: "center",
        justifyContent: _isMobile ? "right" : "center",
        borderTop: "1px solid #B0D1E7",
        minHeight: _isMobile ? "326px" : "105px",
        width: "100%",
        borderRadius: _isMobile ? "14px 14px 0px 0px" : "0px"
      }}
      buttonWrapperClasses={_isMobile ? "consent-buttons-wrapper" : ""}
    >
      <div style={{marginBottom: "8px", color: "#3D3D3D"}}>{t("cookieConsentTitle")}</div>
      {t("cookiesConsentText")}{" "}
      <a href="https://oktocash.eu/cookies-policy/" style={{textDecoration: "underline"}}>{t("cookiePolicy")}</a>.
    </CookieConsent>
  );
};

export default _CookieConsent;
