import "./LocationDetails.css";
import {useState} from "react";
import close_button from "../icons/close_button.svg";
import {getSchedule} from "../services/LocationDetails";
import Schedule from "./Schedule";
import {LocationDetails as LocationDetailsInterface} from "../interfaces/LocationDetails";
import OpenNow from "./OpenNow";
import LocationAddress from "./LocationAddress";
import {useTranslation} from "react-i18next";
import GetDirections from "./GetDirections";
import {isMobile} from "../services/Utilities";

interface LocationDetailsProps {
  locationData?: LocationDetailsInterface;
  showLocationDetails: boolean;
  onClose: () => void;
  showFullImage: boolean;
  setShowFullImage: (x: boolean) => void;
}

const LocationDetails = ({
                           locationData,
                           showLocationDetails,
                           onClose,
                           showFullImage,
                           setShowFullImage,
                         }: LocationDetailsProps) => {
  const {t} = useTranslation();
  const [showSchedule, setShowSchedule] = useState(true);
  const locationSchedule = locationData?.opening_hours?.weekday_text;
  const storeSchedule = getSchedule(locationSchedule);
  const storeImage = locationData?.photos?.[0].getUrl();
  const _isMobile = isMobile();
  if (!showLocationDetails) return null;

  const heightCheck = _isMobile
    ? !locationData?.address?.street && !storeImage && !storeSchedule
      ? {height: "30%"}
      : locationData?.address?.street &&
      ((!storeImage && storeSchedule) || (storeImage && !storeSchedule))
        ? {height: "60%"}
        : locationData?.address?.street && !storeImage && !storeSchedule
          ? {height: "40%"}
          : {height: "100%"}
    : {height: "100%"};


  return (
    <div className={"location-container"}
         style={heightCheck}>
      <div className={"location-inner-container"}>
        <div>
          <div className={"close-button-container"}>
            <img
              className={"close-button"}
              src={close_button}
              alt={"close button"}
              onClick={onClose}
            />
          </div>
          <div
            className={"storeImage-container"}
            onClick={() => setShowFullImage(!showFullImage)}
            style={
              !storeImage
                ? {
                  visibility: "hidden",
                  minHeight: "auto",
                  marginTop: "50px",
                }
                : {
                  backgroundImage: `url(${storeImage})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }
            }
          >
            <img
              className={"storeImage"}
              src={storeImage}
              alt={"alt-text"}
              style={{display: `${showFullImage ? "block" : "none"}`}}
            />
            <div className={"tap-to-view-container"}>
              <p className={"tap-to-view"}>{t("tapToView")}</p>
            </div>
          </div>
          <h2 className={"location-title"}>{locationData?.name}</h2>
          {locationData?.address?.street && <LocationAddress data={locationData}/>}
          {storeSchedule !== undefined && <OpenNow
            showSchedule={showSchedule}
            toggleSchedule={() => setShowSchedule(!showSchedule)}
            locationSchedule={locationSchedule}
            data={locationData}
          />}
          {locationSchedule && (
            <Schedule showSchedule={showSchedule} schedule={storeSchedule}/>
          )}
        </div>
        <div style={{
          display: "flex",
          justifyContent: 'center',
          alignItems: 'flex-end',
          height: '100%',
          // marginBottom: '10px'
        }}>
          <GetDirections
            directionsTo={{lat: locationData?.lat, lng: locationData?.lng}}
          />
        </div>
      </div>
      <div
        className={"overflow"}
        style={{display: `${showFullImage ? "block" : "none"}`, width: ``}}
        onClick={() => setShowFullImage(false)}
      ></div>
    </div>
  );
};
export default LocationDetails;
