import { useCallback, useEffect, useRef, useState } from "react";
import SearchIcon from "../icons/SearchIcon.svg";
import { isMobile } from "../services/Utilities";
import "./MapAutocomplete.css";
import { useTranslation } from "react-i18next";

interface MapAutocompleteProps {
  map: google.maps.Map | undefined;
}
export const MapAutocomplete = ({ map }: MapAutocompleteProps) => {
  const { t } = useTranslation();
  const _isMobile = isMobile();
  const inputRef = useRef<HTMLInputElement>(null);
  const [shouldInit, setShouldInit] = useState(true);
  const [optionsVisible, setOptionsVisible] = useState(false);
  const [place, setPlace] = useState<
    google.maps.places.PlaceResult | undefined
  >();

  const checkVisibility = () => {
    const targetElement = document.getElementsByClassName("pac-container")[0];
    // Check if target element exists and display is not 'none'
    if (
      targetElement &&
      window.getComputedStyle(targetElement).display !== "none"
    ) {
      setOptionsVisible(true);
    } else {
      setOptionsVisible(false);
    }
  };

  if (inputRef.current && shouldInit && map) {
    setShouldInit(false);
    const autocomplete = new google.maps.places.Autocomplete(inputRef.current, {
      fields: ["name", "geometry.location"],
    });
    autocomplete.addListener("place_changed", () => {
      let lat = autocomplete.getPlace().geometry?.location?.lat();
      let lng = autocomplete.getPlace().geometry?.location?.lng();
      if (lat && lng) map.setCenter({ lat, lng });
      // onPlaceSelected(autocomplete.getPlace());
      setPlace(autocomplete.getPlace());
    });

    // Create the search box and link it to the UI element.
    const searchBox = new google.maps.places.SearchBox(inputRef.current);

    searchBox.addListener("places_changed", () => {
      const places = searchBox.getPlaces();
      if (!places || places.length === 0) return;
      // onPlacesChanged(places);
      setPlace(places[0]);
    });
  }

  const changeCenter = useCallback(
    (place: google.maps.places.PlaceResult | undefined) => {
      if (!map || !place || !place.geometry || !place.geometry.location) return;

      map?.setCenter({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
    },
    [map]
  );

  const keyDownHandler = (e: any) => {
    if (e.key === "Enter") {
      changeCenter(place);
    }
  };

  useEffect(() => {
    if (!place) return;
    changeCenter(place);
  }, [changeCenter, place]);

  useEffect(() => {
    // Check visibility when component mounts
    checkVisibility();

    // Listen for visibility changes
    const observer = new MutationObserver(checkVisibility);
    const optionsContainers = document.getElementsByClassName("pac-container");
    if (!optionsContainers || optionsContainers.length <= 0) return;
    for (let index = 0; index < optionsContainers.length; index++) {
      const optionsContainer = optionsContainers[index];

      observer.observe(optionsContainer, {
        attributes: true,
        attributeFilter: ["style"],
      });
    }
  });

  return (
    <div
      style={{
        position: "fixed",
        zIndex: 2,
        width: _isMobile ? "80%" : "40%",
        display: "inline-flex",
        top: "30px",
        transform: "translate(-50%)",
        left: "50%",
      }}
    >
      <input
        ref={inputRef}
        onKeyDown={keyDownHandler}
        placeholder={t("enterLocationPlaceholder")}
        style={{
          width: "100%",
          paddingTop: 16,
          paddingBottom: 16,
          paddingLeft: 24,
          paddingRight: 48,
          background: "white",
          outline: "unset",
          boxShadow: optionsVisible ? "" : "0px 4px 4px rgba(0, 75, 127, 0.20)",
          ...(optionsVisible
            ? { borderTopLeftRadius: 21, borderTopRightRadius: 21 }
            : { borderRadius: 42 }),
          ...(optionsVisible
            ? {
                borderTop: "2px #B0D1E7 solid",
                borderLeft: "2px #B0D1E7 solid",
                borderRight: "2px #B0D1E7 solid",
                borderBottom: "1px #e6e6e6 solid",
              }
            : { border: "2px #B0D1E7 solid" }),
        }}
      />
      <img
        src={SearchIcon}
        alt="Search Icon"
        style={{
          position: "absolute",
          right: "16px",
          top: "50%",
          transform: "translateY(-50%)",
        }}
      />
    </div>
  );
};
